import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  TestIdPartial,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Histogram = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Histogram;
    return <Component {...props} />;
  },
});

const DataCard = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.DataCard;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/histogram.example');
const stateSnippet = require('raw-loader!../../../../data/snippets/histogram-state.example');
// eslint-disable-next-line max-len
const onIsScrollableChangeSnippet = require('raw-loader!../../../../data/snippets/histogram-onIsScrollableChange.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        id="histogram"
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="modules/visualizations/histogram"
        layout="rn"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="isScrollable" types={['boolean[]']}>
            <Text>
              When set to true, the histogram items expand to at least 44px
              width and horizontal scrolling is enabled. This should be used
              with toggleIsScrollable to automatically zoom to the selected item
              when there is not enough space to adhere to the 44px touch target
              minimum standard for histogram items.
            </Text>
          </PropListItem>

          <PropListItem name="items" types={['VisualizationData[]']} isRequired>
            <Text>Data used to populate the histogram.</Text>
          </PropListItem>

          <PropListItem name="labelsToDisplay" types={['string[]']}>
            <Text>
              Labels to display. If used, all labels omitted will not be visible
              in the UI. This should be used for dense data sets.
            </Text>
          </PropListItem>

          <PropListItem name="selectedLabels" types={['string[]']}>
            <Text>
              Array of labels corresponding to the currently selected items. The
              selected labels should match labels provided in the items prop.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onToggleItem" types={['(label: string) => void']}>
            <Text>
              Responds to histogram item press. The provided label string
              matches the label of the toggled item as provided in the items
              prop.
            </Text>
          </PropListItem>

          <PropListItem name="onIsScrollableChange" types={['() => void']}>
            <Text>
              {/* eslint-disable-next-line max-len */}
              Responds to the change of isScrollable value, both by the
              application and the Histogram component. Used to connect automated
              isScrollable change when onToggleItem is called with the
              applications state management for isScrollable. See code example.
            </Text>
            <CodeSnippet
              scope={{ React: React, Histogram: Histogram, DataCard: DataCard }}
              code={onIsScrollableChangeSnippet}
              platform="react"
              disableCodeEditing
            />
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <PropListItem name="ariaLabel" types={['string']}>
            <Text>
              {/* eslint-disable-next-line max-len */}
              Label used to describe the visualization for accessibility
              purposes. This should be what the visualization is displaying;
              i.e. “quarters”.
            </Text>
          </PropListItem>

          <TestIdPartial componentName="histogram" />
        </PropList>
      </Section>

      <Section title="Managing State">
        <Text className="uni-margin--half--bottom">
          To make managing the state of the selected histogram items easier, we
          created <code>useToggleArray()</code>, which is a custom React hook
          that accepts an array of labels representing the initally selected
          items, and returns an array with two values:
        </Text>
        <List type="unordered" className="uni-margin--one--bottom">
          <li>
            <code>selectedLabels</code>: An array of currently selected labels
            to pass through the <code>selectedLabels</code> prop.
          </li>
          <li>
            <code>handleToggleItem</code>: A callback function to pass through{' '}
            <code>onToggleItem</code> to handle toggling the selected state of
            each item.
          </li>
        </List>
        <CodeSnippet
          id="state"
          disableCodeEditing
          code={stateSnippet}
          platform="react"
          gitHubLink="modules/visualizations/histogram"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Modules / Histogram"
      />
    </PlatformTab>
  );
};

export default WebTab;
